import React, { useEffect} from 'react';
import './Savant.css';

function Savant(){
  useEffect(() => {
    setTimeout(() => window.location.replace('https://savant.holeyfox.co'), 2000);
  }, []);
  return (
    <div className="ministry">
      Kainene has moved to a different URL, and I'm redirecting you there. Do not refresh your browser.
    </div>
  );
}

export default Savant;